import { Snapshot } from 'recoil';
import atoms from '../recoil/atoms';
import selectors from '../recoil/selectors';

export const buildSnapView = (snapshot: Snapshot) => ({
  atoms: {
    specs: snapshot.getLoadable(atoms.global.field_Atom('specs')).contents,
    statues: snapshot.getLoadable(atoms.global.field_Atom('statuses')).contents,
    users: snapshot.getLoadable(atoms.global.items_Atom('users')).contents,
    selectedOne: snapshot.getLoadable(atoms.global.selected_Atom('one')).contents,
    selectedTwo: snapshot.getLoadable(atoms.global.selected_Atom('two')).contents,
  },
  selectors: {
    specs: snapshot.getLoadable(selectors.global.specs).contents,
    statues: snapshot.getLoadable(selectors.global.statuses).contents,
    users_one: snapshot.getLoadable(selectors.global.users('one')).contents,
    users_two: snapshot.getLoadable(selectors.global.users('two')).contents,
  },
});
