import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SpecInput from '../inputs/Spec';
import StatusInput from '../inputs/Status';
import UserInput from '../inputs/User';
import selectors from '../recoil/selectors';
import { Field, FieldValue } from '../types';
import { useRecoilValue } from 'recoil';

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: 275,
    margin: `12px 6px`,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CardTwo = () => {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const users: Field = useRecoilValue(selectors.global.users('two'));
  const user = users.items.find(
    (user: FieldValue) => user && user.id && user.id === users.selected
  );

  return (
    <Card className={classes.card} variant="outlined">
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          Dashboard {bull} Two
        </Typography>
        <Typography variant="h5" component="h2">
          Added New Assertion {bull} Live Update
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {(user || { text: 'n/a' }).text} @ 11:25AM EDT
        </Typography>
        <Typography variant="body2" component="p">
          U{bull}app.test.ts
        </Typography>
      </CardContent>
      <CardActions>
        <SpecInput atom={selectors.global.specs} />
        <StatusInput atom={selectors.global.statuses} />
        <UserInput atom={selectors.global.users('two')} />
      </CardActions>
    </Card>
  );
}

export default memo(CardTwo);