import { FieldValue } from '../types';

const get = async (): Promise<Array<FieldValue>> => {
  return [
    { text: 'John Doe', id: 1 },
    { text: 'Jane Doe', id: 2 },
    { text: 'Prescott Smith', id: 3 },
    { text: 'Willard Smith', id: 4 },
  ];
};

export default {
  get,
};
