import React, { useState } from 'react';
import { useRecoilTransactionObserver_UNSTABLE } from 'recoil';
import { buildSnapView } from './snapshot';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import Content from './content';

const useStyles = makeStyles((theme) => ({
  debugger: {
    position: 'absolute',
    width: 600,
    zIndex: 999,
    right: 12,
    bottom: 12,
  },
}));

const DraggablePaper = (props: any) => {
  const [updates, setUpdates] = useState<Object>({});
  useRecoilTransactionObserver_UNSTABLE(({ snapshot, previousSnapshot }) => {
    const latest = buildSnapView(snapshot);
    const previous = buildSnapView(previousSnapshot);
    setUpdates({ latest, previous });
  });

  const classes = useStyles();
  return (
    <Draggable
      axis="both"
      defaultPosition={{ x: 0, y: 0 }}
      handle=".MuiPaper-root"
    >
      <Paper elevation={3} className={classes.debugger}>
        <Content title={`Recoil Latest Snapshots`} data={updates} />
      </Paper>
    </Draggable>
  );
};

export default DraggablePaper;
