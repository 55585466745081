import { FieldValue } from '../types';

const get = async (): Promise<Array<FieldValue>> => {
  return [
    { text: 'App.test.ts', id: 1 },
    { text: 'Router.test.ts', id: 2 },
    { text: 'Auth.test.ts', id: 3 },
  ];
};

export default {
  get,
};
