import React, { memo }  from 'react';
import { useRecoilState } from 'recoil';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Field, FieldValue } from '../types';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const UserInput = (props: any) => {
  const classes = useStyles();
  const [{ selected, items }, setValue] = useRecoilState<Field>(props.atom);

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  const list = items.map((user: FieldValue) => (
    <MenuItem value={user.id} selected={user.id === selected}>
      {user.text}
    </MenuItem>
  ));

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="user-outlined-label">User</InputLabel>
      <Select
        labelId="user-outlined-label"
        id="user-outlined"
        value={selected || ''}
        onChange={handleChange}
        label="user-list"
      >
        {list}
      </Select>
    </FormControl>
  );
}

export default memo(UserInput)