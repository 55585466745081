import React, { useState } from 'react';
import { useRecoilTransactionObserver_UNSTABLE } from 'recoil';
import { buildSnapView } from './snapshot';
import { diff } from 'json-diff';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

import Content from './content';

const useStyles = makeStyles((theme) => ({
  debugger: {
    position: 'absolute',
    width: 600,
    zIndex: 999,
    left: 12,
    bottom: 12,
    maxHeight: 800,
    overflow: 'hidden',
  },
}));

const DraggablePaper = (props: any) => {
  const [updates, setUpdates] = useState<Array<any>>([]);
  useRecoilTransactionObserver_UNSTABLE(({ snapshot, previousSnapshot }) => {
    const latest = buildSnapView(snapshot);
    const previous = buildSnapView(previousSnapshot);
    setUpdates([diff(previous, latest), ...updates]);
  });

  const classes = useStyles();
  return (
    <Draggable
      axis="both"
      defaultPosition={{ x: 0, y: 0 }}
      handle=".log-header"
    >
      <Paper elevation={3} className={classes.debugger}>
        <Content title={`Recoil Log Diffs`} data={updates} />
      </Paper>
    </Draggable>
  );
};

export default DraggablePaper;
