import { useEffect } from 'react';
import { useRecoilCallback } from 'recoil';
import services from '../services';
import atoms from '../recoil/atoms';

const Init = (props: any) => {
  const loadUsers = useRecoilCallback(
    ({ snapshot, set }) => async (projectId, userId) => {
      const [users, specs, statuses] = await Promise.all([
        services.users.get(),
        services.specs.get(),
        services.statuses.get(),
      ]);
      set(atoms.global.items_Atom('users'), users);
      set(atoms.global.field_Atom('specs'), {items: specs, selected: 0});
      set(atoms.global.field_Atom('statuses'), {items: statuses, selected: 0});
    }
  );

  useEffect(() => {
    loadUsers(1, 2);
  });

  return props.children;
};

export default Init;
