import React, { memo } from 'react';
import { useRecoilState } from 'recoil';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Field, FieldValue } from '../types';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

interface CardData {
  spec: string;
}

const SpecInput = (props: any) => {
  const classes = useStyles();
  const [{ selected, items }, setValue] = useRecoilState<Field>(props.atom);

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  const list = items.map((specs: FieldValue) => (
    <MenuItem value={specs.id} selected={specs.id === selected}>
      {specs.text}
    </MenuItem>
  ));

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="spec-outlined-label">Spec</InputLabel>
      <Select
        labelId="spec-outlined-label"
        id="spec-outlined"
        value={selected || ''}
        onChange={handleChange}
        label="Spec"
      >
        {list}
      </Select>
    </FormControl>
  );
}

export default memo(SpecInput);