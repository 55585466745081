import { atomFamily } from 'recoil';
import { FieldValue, Field } from '../../types';

const field_Atom = atomFamily<Field,string>({
  key: 'field_Atom',
  default: {
    items: [],
    selected: 0,
  }
});

const items_Atom = atomFamily<Array<FieldValue>,string>({
  key: 'items_Atom',
  default: []
});

const selected_Atom = atomFamily<number,string>({
  key: 'selected_Atom',
  default: 0,
});

export default {
  global: {
    field_Atom,
    items_Atom,
    selected_Atom,
  },
};
