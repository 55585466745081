import { selector, selectorFamily, SerializableParam } from 'recoil';
import atoms from '../atoms';
import { Field, FieldValue } from '../../types';

const users = selectorFamily<Field, SerializableParam>({
  key: 'users_Selector',
  get: (input: SerializableParam) => ({ get }) => {
    const users = get<Array<FieldValue>>(atoms.global.items_Atom('users'));
    const selected = get<number>(atoms.global.selected_Atom(input as string));
    return { items: users, selected };
  },
  set: (input: SerializableParam) => ({ set, get }, newValue: any) => {
    set(atoms.global.selected_Atom(input as string), newValue);
  },
});

const specs = selector<Field>({
  key: 'specs_Selector',
  get: ({ get }) => {
    return get<Field>(atoms.global.field_Atom('specs'));
  },
  set: ({ set }, newValue: any) => {
    set(atoms.global.field_Atom('specs'), (prevState) => ({
      ...prevState,
      selected: newValue as number,
    }));
  },
});

const statuses = selector<Field>({
  key: 'statuses_Selector',
  get: ({ get }) => {
    return get<Field>(atoms.global.field_Atom('statuses'));
  },
  set: ({ set }, newValue: any) => {
    set(atoms.global.field_Atom('statuses'), (prevState) => ({
      ...prevState,
      selected: newValue as number,
    }));
  },
});

export default {
  global: {
    specs,
    statuses,
    users,
  },
};
