import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Pre, Line, LineContent } from './code';
import Highlight, { defaultProps } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/github';

const useCardStyles = makeStyles((theme) => ({
  card: {
    minWidth: 275,
  },
  item: {
    fontSize: 14,
  },
  header: {
    marginBottom: 0,
    fontSize: 16,
    cursor: 'grabbing',
  },
}));

const Content = (props: any) => {
  const classes = useCardStyles();
  const exampleCode = JSON.stringify(props.data, null, 2);
  return (
    <Card className={classes.card} variant="outlined">
      <CardContent>
        <Typography
          className={`${classes.header} log-header`}
          color="textSecondary"
          gutterBottom
          style={{cursor: 'grabbing'}}
        >
          {props.title}
        </Typography>
        <Highlight
          {...defaultProps}
          theme={theme}
          code={exampleCode}
          language="jsx"
        >
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <Pre
              className={className}
              style={{
                ...style,
                overflowX: 'hidden',
                overflowY: 'auto',
                maxHeight: 600,
                backgroundColor: '#fff',
                marginTop: 0,
                marginBottom: 0,
                border: '1px solid #ccc',
                borderLeft: 0,
              }}
            >
              {tokens.map((line, i) => (
                <Line key={i} {...getLineProps({ line, key: i })}>
                  <LineContent>
                    {line.map((token, key) => (
                      <span key={key} {...getTokenProps({ token, key })} />
                    ))}
                  </LineContent>
                </Line>
              ))}
            </Pre>
          )}
        </Highlight>
      </CardContent>
    </Card>
  );
};

export default Content;
