import { FieldValue } from '../types';

const get = async (): Promise<Array<FieldValue>> => {
  return [
    { text: 'Pending', id: 1 },
    { text: 'Success', id: 2 },
    { text: 'Skipped', id: 3 },
    { text: 'Failed', id: 4 },
  ];
};

export default {
  get,
};
