import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CardOne from './cards/one';
import CardTwo from './cards/two';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: `${theme.spacing(1) + 'px'} auto`,
      width: 'auto',
      height: theme.spacing(16),
    },
  },
  paper: {
    boxShadow: `none`,
    height: `auto`,
    width: 1200,
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const App = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <CardOne />
        <CardTwo />
      </Paper>
    </div>
  );
};

export default App;
